import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, Input } from '@angular/core';
import { interval, Subscription, timer , BehaviorSubject } from 'rxjs';
import { DialerComponent } from '../dialer/dialer.component';
import { LoggingService } from 'src/app/services/logging.service';
import { ThemeService } from '../../services/theme.service';
import { SVGIcons } from '@mitel-internal/cloudlink-components-catalogue';
import { SoftphoneLauncherService } from 'src/app/services/softphone-launcher.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { CallHistoryService } from 'src/app/services/call-history.service';
import { ICall } from 'src/app/interfaces/call-history';
import { ContactService } from 'src/app/services/contact.service';
import { map } from 'rxjs/operators';
import { Contact } from 'src/app/model/contact';
import { ContactActivity, ContactPresence } from 'src/app/enums/contact-presence';
import { TranslateService } from '@ngx-translate/core';
import { AppUtils } from 'src/app/common/utils';
import { DeviceDetectService } from 'src/app/services/DeviceDetectService.service';
import { NotifyService } from 'src/app/services/notify.service';
import { CallHistoryResp } from 'src/app/model/call-history';
import { ContactUpdateEnum } from 'src/app/enums/contact-update-enum';
import { IContactUpdate } from 'src/app/interfaces/contact-update';
import { CloudAuthenticationService } from 'src/app/services/cloud-authentication.service';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
import { pbxType } from 'src/app/common/constants';

const lsKey = '@msSpeedDialContacts';
const blankAvatar = 'assets/icons/blank-avatar.png';
const MITEL_CUST_CL_ACC_ID = '@mitel/CLAccountId';
@Component({
  selector: 'app-call-history',
  templateUrl: './call-history.component.html',
  styleUrls: ['./call-history.component.less'],
})
export class CallHistoryComponent implements OnInit, OnDestroy, AfterViewInit {
  isCallRecordsClicked = false;
  callRecordsList: ICall[] = [];
  callRecordsMap: Map<string, ICall> = new Map();
  showLoader = true;
  selectedFilter = 'all';
  @ViewChild(DialerComponent) dialer: DialerComponent;
  svgIcons = SVGIcons;
  scrollLoading = false;
  defaultRecNo = 20;
  contactCache: Map<string, any> = new Map();
  days: string[];
  months: string[];
  presenceUpdateSubscription: Subscription;
  selectedRow: string;
  selectedRowStyle = ''; // Just added for transition effect
  searchText: string;
  newContactsCount: number;

  selectedTab = 'callhistory';
  titleCallHistory = '';
  callHistorySubscription: Subscription;
  voicemailSubscription: Subscription;
  reSyncIntervalSubscription: Subscription = null;
  contactsUpdateSubscription: Subscription;
  voicemailIndicator = false;
  vmPilotNumber: any;
  pbx: string;

  @Input() isVoicemailWaitingIndicator: boolean;

  get lastSyncTime(): any {
    return this.callHistorySvc.lastSyncTime;
  }

  set lastSyncTime(time) {
    this.callHistorySvc.lastSyncTime = time;
  }

  get callRecords(): ICall[] {
    return this.callRecordsList;
  }

  get isMobile(): boolean {
    return this.breakPointService.isMobile;
  }

  get callHIstoryContacts(): any {
    return this.callHistorySvc.contacts;
  }

  set callHIstoryContacts(val) {
    this.callHistorySvc.contacts = val;
  }

  get speedDialsLimit(): boolean {
    return this.contactSvc.getSpeedDialsLimit();
  }
  get callHistoryPhoneNumbers(): any{
    return this.callHistorySvc.phoneNoLists.values();
  }

  constructor(
    private themeSvc: ThemeService,
    private softphoneLauncher: SoftphoneLauncherService,
    private analytics: AnalyticsService,
    private callHistorySvc: CallHistoryService,
    private contactSvc: ContactService,
    private translate: TranslateService,
    private breakPointService: DeviceDetectService,
    private notifyService: NotifyService,
    private clAuth: CloudAuthenticationService
  ) {
  }

  ngOnInit(): void {
    this.getTranslations();
    this.themeSvc.setStyles('oneapp');
    this.subscribetoCloudlinkAuth();
    this.callHistory();
    this.subscribeToPresence();
    this.setupSubscription();
    this.getVmPilotNumber();
    this.getAccountPBX(localStorage.getItem(MITEL_CUST_CL_ACC_ID)).then(res => {
      this.pbx = res;
      this.setVoicemailTab();
    });
  }

  public async getVmPilotNumber(): Promise<void> {
    try {
      this.vmPilotNumber = await this.callHistorySvc.getVmPilotNumber(this.clAuth.principalId, this.clAuth.extensionId);
      LoggingService.info('[CallHistoryComponent.getVmPilotNumber()]', this.vmPilotNumber);
      this.voicemailIndicator = this.vmPilotNumber?.messageWaiting;
    } catch (err) {
      LoggingService.error('[CallHistoryComponent.getVmPilotNumber()] error in callHistorySvc.getVmPilotNumber', err);
    }
  }

  ngAfterViewInit(): void {
    this.subscribetoUpdatedContacts();
  }

  subscribetoUpdatedContacts(): any {
    this.contactsUpdateSubscription = this.contactSvc.contactsUpdatedObserver.subscribe((contactUpdate) => {
      this.updateSpeedDial(contactUpdate);
    });
  }

  updateSpeedDial(contactUpdate: IContactUpdate): void {
    this.contactCache.forEach((contact) => {
      if (contact.id === contactUpdate.contactDetails.id){
        if (contactUpdate.operation === ContactUpdateEnum.Add) {
          contact.canAddToFav = false;
          contact.isAddedToSpeedDial = true;
        } else {
          contact.canAddToFav = true;
          contact.isAddedToSpeedDial = false;
        }
      }
    });
  }

  setupSubscription(): void {
    this.callHistorySubscription = this.notifyService.callHistoryNotification$.subscribe((message) => {
      if (message?.content) {
        const eventItem = new CallHistoryResp(message.content);
        if (message.content.eventType === 'CallHistoryNotification') {
          this.callHistorySvc.convertNotificaonToCallrecord(eventItem)
            .subscribe((records: ICall[]) => {
              LoggingService.info('[CallHistoryComponent.setupSubscription]: notification object to call record object', records);
              this.updateCallRegistry(records);
              this.updatePhoneSearch(records).then(() => {
                this.updateContactCacheForAllRecords();
                this.callRecordsList = [...this.callRecordsMap.values()];
                this.showLoader = false;
                this.scrollLoading = false;
              });
          });
        }
        else if (message.content.eventType === 'FeatureStatusNotification') {
          if (eventItem.callId) { // Remove single call record events
           this.callRecordsMap.delete(eventItem.callId);
           this.callRecordsList = [...this.callRecordsMap.values()];
          } else { // Remove all call records events
            this.callRecordsMap.clear();
            this.callRecordsList = [...this.callRecordsMap.values()];
          }
        }
      }
    });
    this.voicemailSubscription = this.notifyService.voicemailNotification$.subscribe((message) => {
      this.setVoicemailIndicator(message);
    });
  }

  subscribetoCloudlinkAuth(): void {
    this.callHistorySvc.subscribeToClAuth().subscribe((result) => {
      if (result) {
        LoggingService.info('[CallHistoryComponent.setupNotify CL-logged in:]', result);
        this.setupNotify();
      }
    });
  }

  setupNotify(): void {
    this.notifyService.setupNotify();
  }
  getTranslations(): void {
    this.translate.get('call-history.days').subscribe((tDays) => {
      this.days = tDays.split('~');
    });
    this.translate.get('call-history.months').subscribe((tMonths) => {
      this.months = tMonths.split('~');
    });
    this.translate.get('call-history.title').subscribe((title) => {
      this.titleCallHistory =  title;
    });
  }

  authValidate(): boolean {
    return this.callHistorySvc.flagClLoggedIn;
  }

  reSyncCallRecords(): void {
    if (this.reSyncIntervalSubscription) {
      return;
    }
    this.reSyncIntervalSubscription = interval(5000)
      .pipe(
        map(() => {
          return Date.now() - (0 || this.lastSyncTime) > 10000 ? true : false;
        })
      )
      .subscribe((response) => {
        if (response) {
          LoggingService.info('Syncing the history records.', 'Last syned at: ', this.lastSyncTime);
          this.lastSyncTime = Date.now();
          this.getCallRecords();
        }
      });
  }


  callHistory(): void {
    if (this.callRecords?.length) {
      return;
    }
    this.showLoader = true;
    this.getCallRecords();
  }

  getCallRecords(skip = 0, top = this.defaultRecNo): void {
    this.callHistorySvc.getUserCallRecords(skip, top, this.callRecordsMap).subscribe((records: ICall[]) => {
      this.updateCallRegistry(records);
      this.updatePhoneSearch(records).then(() => {
        if (this.callHIstoryContacts.length) {
          this.updateContactCacheForAllRecords();
        } else {
          this.setCallRecords();
        }
        this.callRecordsList = [...this.callRecordsMap.values()];
        this.showLoader = false;
        this.scrollLoading = false;
      });
    });
  }

  dialNumber(num?: string, isOutbound: boolean = false): void {
    if (num !== undefined) {
      this.softphoneLauncher.dial(num, isOutbound);
    }
  }

  public makeCallFromHistory(call: ICall): void {
    this.analytics.record('make-call-from-history');
    LoggingService.info(`[Call-History-Component.makeCallFromHistory] calling from call-history, callId: ${call.callId}`);
    if (call?.direction === 'outbound') {
      this.dialNumber(
        this.getExtension(call, true),
        call?.dialedDigits ? this.pbx !== pbxType.MiVB : true
      );
    } else {
      this.dialNumber(this.getExtension(call, true));
    }
  }

  public checkExtension(call: ICall): string {
    const userId = this.getUserId(call);
    if (call?.direction === 'outbound') {
      if (call?.toName === 'undefined' || call?.to === call?.toName || call?.to === undefined) {
        if (this.contactCache.get(userId)?.displayName) {
          return call?.to;
        }
        return '';
      } else {
        return call?.to;
      }
    } else {
      if (call?.fromName === 'undefined' || call?.from === call?.fromName || call?.from === undefined) {
        if (this.contactCache.get(userId)?.displayName) {
          return call?.from;
        }
        return '';
      } else {
        return call?.from;
      }
    }
  }

  public getExtension(call: ICall, makeCall: boolean = false): string {
    if (makeCall && this.pbx === pbxType.MiVB) {
      if (call?.direction === 'outbound') {
        LoggingService.info('[Call-History-Component.getExtension]: making an outbound call', [call?.dialedDigits, call?.to]);
        return call?.to?.includes(call?.dialedDigits) ? call?.dialedDigits : call?.to;
      }
      LoggingService.info('[Call-History-Component.getExtension]: making an inbound call', [call?.dialedDigits, call?.from]);
      return call?.from?.includes(call?.dialedDigits) ? call?.dialedDigits :  call?.from;
    }
    return call?.direction === 'outbound' ? call?.to : call?.from;
  }

  public getCallerName(call: ICall): string {
    const userId = this.getUserId(call);
    if (this.contactCache.has(userId)) {
      if (this.contactCache.get(userId).displayName) {
        return this.contactCache.get(userId).displayName;
      } else if (this.contactCache.get(userId).name) {
        return this.contactCache.get(userId).name;
      } else {
        if (call?.direction === 'outbound') {
          return call?.toName !== 'undefined' ? call?.toName : call.to;
        } else {
          return call?.fromName !== 'undefined' ? call?.fromName : call.from;
        }
      }
    } else {
      if (call?.direction === 'outbound') {
        return call?.toName !== 'undefined' ? call?.toName : call.to;
      } else {
        return call?.fromName !== 'undefined' ? call?.fromName : call.from;
      }
    }
  }

  public getInitials(call: ICall): string {
    return AppUtils.getInitials(this.getCallerName(call));
  }

  public getAvatar(call: ICall): string {
    if (call?.avatars) {
      const scaled = 'scaled';
      return call?.avatars[scaled];
    } else {
      return blankAvatar;
    }
  }

  public async deleteCallFromHistory(call: ICall): Promise<void> {
    const callId = call.callId;
    const param = {
      callId,
      body: {
        hide: true,
      },
    };
    call.isActionInProgress = true;
    try {
      await this.callHistorySvc.updateCallRecord(param);
      this.callRecordsMap.delete(callId); // remove the call record from the map
      this.callRecordsList = [...this.callRecordsMap.values()];
    } catch (err) {
      call.isActionInProgress = false;
      LoggingService.error('[Call History Component] Delete record failed', err);
    }
  }

  onScrollDown(): void {
    if (!this.scrollLoading) {
      this.scrollLoading = true;
      this.getCallRecords(this.callRecords.length);
    }
  }

  updateCallRegistry(calls: ICall[]): void {
    if (calls?.length) {
      calls.forEach((call) => {
        if (
          !this.callRecordsMap.get(call.callId) ||
          this.callRecordsMap.get(call.callId).completed !== call.completed
        ) {
          this.callRecordsMap.set(call.callId, call);
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.reSyncIntervalSubscription) {
      this.reSyncIntervalSubscription.unsubscribe();
    }
    this.reSyncIntervalSubscription = null;

    if (this.presenceUpdateSubscription) {
      this.presenceUpdateSubscription.unsubscribe();
    }

    if (this.callHistorySubscription) {
      this.callHistorySubscription.unsubscribe();
    }

    if (this.voicemailSubscription) {
      this.voicemailSubscription.unsubscribe();
    }

    if ( this.contactsUpdateSubscription) {
      this.contactsUpdateSubscription.unsubscribe();
    }

    this.notifyService.unsubscribe();
  }

  getLocalDate(date: any): any {
    const currDate = new Date();
    let sDate: string;
    const monthNames = this.months;
    if (typeof date === 'string') {
      date = new Date(date);
    }
    if (date.toLocaleDateString() === new Date().toLocaleDateString()) {
      return this.days[7] + (this.isMobile ? '\n' : ' ') + date.toLocaleTimeString();
    } else if (date.toLocaleDateString() === new Date(Date.now() - 24 * 3600 * 1000).toLocaleDateString()) {
      return this.days[8] + (this.isMobile ? '\n' : ' ') + date.toLocaleTimeString();
    } else if (date > new Date(currDate.getFullYear(), currDate.getMonth(), currDate.getDate() - 5)) {
      switch (date.getDay()) {
        case 0:
          sDate = this.days[0];
          break; // Sunday
        case 1:
          sDate = this.days[1];
          break; // Monday
        case 2:
          sDate = this.days[2];
          break;
        case 3:
          sDate = this.days[3];
          break;
        case 4:
          sDate = this.days[4];
          break;
        case 5:
          sDate = this.days[5];
          break;
        case 6:
          sDate = this.days[6];
          break; // Saturday
      }
      return sDate + (this.isMobile ? '\n' : ' ') + date.toLocaleTimeString();
    } else {
      return (
        monthNames[date.getMonth()] +
        ' ' +
        date.getDate() +
        ',' +
        date.getFullYear() +
        (this.isMobile ? '\n' : ' ') +
        date.toLocaleTimeString()
      );
    }
  }

  selectDirectory(option: string): void {
    this.selectedFilter = option;
  }

  isPresentInSpeedDial(clUserinfo: any): boolean {
    let matchedContact;
    try {
      const speedDialContacts = JSON.parse(localStorage.getItem(lsKey)) || [];
      if (speedDialContacts.length > 0) {
        matchedContact = speedDialContacts.filter(
          (contact: { id: string; name: string; email: string; hasPhone: boolean }) =>
            contact.email === clUserinfo.email
        );
        if (matchedContact.length > 0) {
          this.contactCache.set(clUserinfo.userId, {
            ...matchedContact[0],
            canAddToFav: false,
            isAddedToSpeedDial: true,
          });
          return true;
        }
      }
    } catch (err) {
      LoggingService.error('[Call History Component] Error in parsing the json data', err);
    }
    return false;
  }

  canAddtoSpeedDial(call: ICall): boolean {
    const userId = this.getUserId(call);
    return this.contactCache.get(userId) && this.contactCache.get(userId).canAddToFav;
  }

  isAddedtoSpeedDial(call: ICall): boolean {
    const userId = this.getUserId(call);
    return this.contactCache.get(userId) && this.contactCache.get(userId).isAddedToSpeedDial;
  }

  async updatePhoneSearch(calls: ICall[]): Promise<void> {
    try {
      const phoneNos = [...this.callHistoryPhoneNumbers]as any[];
      const phoneMap = new Map();
      let uniqueNumbers = [];
      const countryCode = (await this.callHistorySvc.getCountryCode(localStorage.getItem(MITEL_CUST_CL_ACC_ID)))?.location.address.country;
      LoggingService.info('[Call HIstory Component: updatePhoneSearch] got the country code', countryCode);
      await this.contactSvc.getPersonalContacts();

      for (const item of phoneNos) {
        const arr = this.getNewNumber(item.userid, item.direction, countryCode);
        phoneMap.set(item.userid, arr);
        uniqueNumbers = [...uniqueNumbers, ...arr];
      }
      // using set to remove duplicates
      const uniqueSet = new Set([...uniqueNumbers]);
      uniqueNumbers = Array.from(uniqueSet);

      if (phoneNos.length) {
        let msPhoneUsers = [];
        msPhoneUsers =  (await this.contactSvc.getContactsByPhoneNumbers(uniqueNumbers) || []);
        if (msPhoneUsers.some(usr => usr.contacts == null)) {
          const personalContacts = (await this.contactSvc.getCachedPersonalContacts() || []);
          if (personalContacts.length > 0) {
            // merge azure contacts with personnal contacts
            msPhoneUsers.forEach(item => {
              if ( item.contacts == null ) {
                // search phone number on personal contacts
                const pContact = personalContacts.find(contact =>
                  contact.allPhones.length > 0  && contact.allPhones.some(i => i.number === item.phone));
                if (pContact) {
                  item.contacts = pContact;
                }
              }
            });
          }
        }
        msPhoneUsers = msPhoneUsers.filter(item => item.contacts !== null);
        let contactEmptyCheck;
        phoneMap.forEach((item, id) => {
          contactEmptyCheck = null;
          msPhoneUsers.forEach(item1 => {
            if (item.includes(item1?.phone)) {
              this.isExternalPresentInSpeedDial(id, item1);
              contactEmptyCheck = item1.contacts;
            }
          });
          if (calls?.length) {
            calls.forEach((call) => {
              if ((call.direction === 'inbound' ? call.fromPrincipalId : call.toPrincipalId) === id
              && contactEmptyCheck != null && this.callRecordsMap.get(call.callId)) {
                const callItem = this.callRecordsMap.get(call.callId);
                const key = 'initials';
                callItem[key] = item.initials;
                this.callRecordsMap.set(call.callId, callItem);
              }
            });
          }
        });
      }
    } catch (err) {
      LoggingService.error('[Call HIstory Component] Error in updatePhoneSearch', err);
    }
  }

  isExternalPresentInSpeedDial(id: string, item: any): void {
    try {
      const speedDialContacts = JSON.parse(localStorage.getItem(lsKey)) || [];
      if (speedDialContacts.length > 0) {
        const matchedContact = speedDialContacts.filter((contact) => contact.id === item.contacts?.id);
        if (matchedContact.length > 0) {
          this.contactCache.set(id, { ...item.contacts, canAddToFav: false, isAddedToSpeedDial: true });
          return;
        }
      }
      this.contactCache.set(id, { ...item.contacts, canAddToFav: true, isAddedToSpeedDial: false });
    } catch (err) {
      LoggingService.error('[Call History Component isExternalPresentInSpeedDial] Error in parsing the json data', err);
    }
  }

  async cacheContact(contacts: any[], retryAttempt = 0): Promise<any> {
    const emailIds = [];
    contacts.forEach((contact) => {
      const userId = contact.userId;
      let contactInfo: any;
      if (!userId) {
        return;
      }
      contactInfo = this.contactCache.get(userId) || {};
      if (contactInfo?.canAddToFav !== undefined) {
        return;
      }
      if (this.isPresentInSpeedDial(contact)) {
        if (--this.newContactsCount === 0) {
          this.setCallRecords();
        }
        // Check if the user info is alreay present in speed dial
        return;
      }
      if (contact.email) {
        emailIds.push(contact.email);
      }
    });
    if (!(emailIds.length )) { // return if array is empty
      return;
    }
    try {
      let msUsersAll = [];
      let msUsers = [];
      if ( emailIds.length) {
        msUsers = (await this.contactSvc.getContactsByEmail(emailIds) || []);
        msUsers = msUsers.map((user) => user.contacts);
        msUsersAll = [...msUsersAll, ...msUsers];
      }
      msUsersAll = msUsersAll.reduce((acc, item) => [...acc, ...item], []);
      msUsersAll.forEach((msuser: any) => {
        const userId = this.callHIstoryContacts.filter(
          (contact) => contact.email === msuser.mail || contact.email === msuser.userPrincipalName
        )[0]?.userId;
        if (userId) {
          if (msuser && msuser.allPhones && msuser.allPhones.length > 0) {
            this.contactCache.set(userId, { ...msuser, canAddToFav: true, isAddedToSpeedDial: false });
          } else {
            this.contactCache.set(userId, { ...msuser, canAddToFav: false, isAddedToSpeedDial: false });
          }
        }
        if (--this.newContactsCount === 0) {
          this.setCallRecords();
        }
      });
    } catch (err) {
      LoggingService.error('[Call HIstory Component] Error in fetching the user from MS', err);
      retryAttempt++;
      if (retryAttempt < 3) {
        this.cacheContact(contacts, retryAttempt);
      }
    }
  }

  public async addRemoveSpeedDial(call: ICall): Promise<void> {
    const userId = this.getUserId(call);
    let action = 'add';
    try {
      call.isActionInProgress = true;
      const contact = this.contactCache.get(userId);
      if (this.isAddedtoSpeedDial(call)) {
        action = 'remove';
        await this.callHistorySvc.deleteContactFromSpeedDial(contact);
        LoggingService.info('[Call HIstory Component] Speed dial removed.');
        contact.canAddToFav = true;
        contact.isAddedToSpeedDial = false;
      } else {
        await this.callHistorySvc.addContactToSpeedDial(contact);
        this.analytics.record('contact-added-to-speeddials');
        LoggingService.info('[Call HIstory Component] Added to speed dial list.');
        contact.canAddToFav = false;
        contact.isAddedToSpeedDial = true;
      }
      call.isActionInProgress = false;
      this.contactCache.set(userId, contact);
    } catch (err) {
      call.isActionInProgress = false;
      LoggingService.error(
        '[Call HIstory Component] Error in' + action === 'add' ? 'adding ' : 'removing ' + 'speed dial contact',
        err
      );
    }
  }

  subscribeToPresence(): void {
    this.presenceUpdateSubscription = timer(2000, 5000).subscribe(async () => {
      this.getUpdatedPresence();
    });
  }

  getUpdatedPresence(): void {
    if (this.contactCache?.size > 0) {
      const contactIds = [...this.contactCache.values()]
        .map((mscontact: Contact) => mscontact.id)
        .filter((mscontactId) => mscontactId !== null && mscontactId !== undefined && mscontactId !== '');
      if (contactIds && contactIds.length) {
        this.contactSvc.getPresenceForUsers(contactIds).then((presenceResponse) => {
          if (presenceResponse.size) {
            [...this.contactCache.values()].forEach((cachedContact) => {
              const userPresence = presenceResponse.get(cachedContact?.id);
              cachedContact.presence = userPresence?.presence || ContactPresence.PresenceUnknown;
              cachedContact.activity = userPresence?.activity || ContactActivity.PresenceUnknown;
            });
          }
        });
      }
    }
  }

  getContactPresence(call: ICall): string {
    const userId = this.getUserId(call);
    return (this.contactCache.get(userId) || { presence: ContactActivity.PresenceUnknown }).presence;
  }

  getContactActivity(call: ICall): string {
    const userId = this.getUserId(call);
    return (this.contactCache.get(userId) || { activity: ContactActivity.PresenceUnknown }).activity;
    // set unknown for all the users which are not valid
  }

  updateContactCacheForAllRecords(): void {
    const tempContacts = new Set();
    this.newContactsCount = this.callHIstoryContacts.length;
    if (this.newContactsCount === 0) {
      this.setCallRecords();
    }
    this.callHIstoryContacts.forEach((contact) => {
      if (!this.contactCache.has(contact.userId)) {
        tempContacts.add(contact);
      } else if (--this.newContactsCount === 0) {
        this.setCallRecords();
      }
    });
    if (tempContacts) {
      this.cacheContact([...tempContacts]);
    }
  }

  getUserId(call: ICall): string {
    return call.direction === 'outbound' ? call.toPrincipalId : call?.fromPrincipalId;
  }

  getBackgroundColor(call: ICall): string {
    const gray = '#7e858b';
    const userId = this.getUserId(call);
    const contact = this.contactCache.get(userId) ;
    if (contact) {
      // Check if the contact in cache exists
      if (contact?.bgColor) {
        return contact?.bgColor;
      } else {
        const randomColor = AppUtils.getRandomColor();
        contact.bgColor = randomColor;
        return randomColor;
      }
    }
    else {
      return gray;
    }
  }

  public showHideButtons(call: ICall): void {
    if (!this.isMobile) {
      return;
    }
    this.selectedRow = call.callId === this.selectedRow ? null : call.callId;
    timer(100).subscribe(() => (this.selectedRowStyle = this.selectedRow));
  }

  public setCallRecords(): void {
    this.callHIstoryContacts = [];
    this.showLoader = false;
    this.scrollLoading = false;
    this.callRecordsList = [...this.callRecordsMap.values()];
  }

  public setVoicemailIndicator(message): void {
    if (message.content.messageWaiting) {
      this.voicemailIndicator = true;
    }else {
      this.voicemailIndicator = false;
    }
  }

  public getNewNumber(phoneNo: string, direction: string, countryCode: string): Array<string> {
    try {
      LoggingService.info('[call-history-component getNewNumber method]: got the phone number', phoneNo);
      const phoneNosWithoutSpaces = phoneNo.replace(/[^a-zA-Z0-9+]/g, '');
      const phoneNosWithoutPlus = phoneNo.replace(/[^a-zA-Z0-9]/g, '');
      // removing the extra prefix added by pbx
      const originalPhoneNo = this.removePbxPrefix(phoneNo, direction);
      // calling format method for additional formatted numbers
      const formattedNumber = this.formattedNumber(originalPhoneNo, countryCode);
      // using set to remove duplicates
      const allNumbers = new Set([phoneNo, phoneNosWithoutSpaces, phoneNosWithoutPlus, originalPhoneNo, ...formattedNumber]);
      return Array.from(allNumbers);
    } catch (err) {
      LoggingService.error('[call-history-component] error in getNewNumber', err);
      return new Array(phoneNo);
    }
  }

  public formattedNumber(phoneNumber: string, countryCode: string): Set<string> {
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    const parsedNumber = phoneNumberUtil.parseAndKeepRawInput(phoneNumber, countryCode);
    const phoneNumberSet = new Set<string>();
    [PhoneNumberFormat.E164, PhoneNumberFormat.NATIONAL, PhoneNumberFormat.INTERNATIONAL].forEach((format) => {
      phoneNumberSet.add(phoneNumberUtil.format(parsedNumber, format));
    });
    phoneNumberSet.add(phoneNumberUtil.formatInOriginalFormat(parsedNumber, countryCode));
    phoneNumberSet.add(phoneNumberUtil.formatOutOfCountryCallingNumber(parsedNumber, 'US'));
    phoneNumberSet.add(phoneNumberUtil.formatOutOfCountryCallingNumber(parsedNumber, 'CH'));
    LoggingService.info('[callHistoryComponent.formattedNumber] all formats added', [...phoneNumberSet]);
    return phoneNumberSet;
  }

  public async getAccountPBX(accId): Promise<string> {
    LoggingService.info('[callHistoryComponent.getAccountPBX]: calling accountTag api', accId);
    const result = await this.callHistorySvc.getAccountTag(accId, 'products');
    LoggingService.info('[callHistoryComponent]:getAccountPBX', result);
    if (result.some(i => i === pbxType.MiVO5000)) {
      return pbxType.MiVO5000;
    } else if (result.some(i => i === pbxType.MXONE)) {
      return  pbxType.MXONE;
    } else if (result.some(i => i === pbxType.MiVO400)) {
      return pbxType.MiVO400;
    } else if (result.some(i => i === pbxType.MiVB)) {
      return pbxType.MiVB;
    }
    return null;
  }

  public setVoicemailTab(): void {
    if (this.pbx === pbxType.MiVB || this.pbx === pbxType.MiVO400) {
      this.isVoicemailWaitingIndicator = true;
    }
    if (this.clAuth.isDev && this.pbx === pbxType.MiVO5000) {
      this.isVoicemailWaitingIndicator = true;
    }
    return;
  }

  public removePbxPrefix(phone: string, direction: string): string {
    if (this.pbx === pbxType.MiVB && direction === 'outbound') {
      LoggingService.info(`[callHistoryComponent.removePbxPrefix] has ${pbxType.MiVB} pbx type`, phone);
      return phone.slice(1);
    }
    LoggingService.info(`[callHistoryComponent.removePbxPrefix] removing leading zeroes`, phone);
    return phone.replace(/^0+/, '');
  }
}
